<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbook <span>Edit Playbook Message</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <ul class="tab_sec">
                        <li :class="messageTab === 'invitation' ? 'active' : ''" @click="messageTab = 'invitation'">Invitation Message</li>
                        <li :class="messageTab === 'resend' ? 'active' : ''" @click="messageTab = 'resend'">Resend Password Message</li>
                    </ul>
                    <Form v-slot="{ errors }" ref="edit-delivery-message-form">
                        <div v-if="messageTab === 'invitation'" class="mt-3">
                            <div class="capsule_elm mb-2">
                                <h5>{{form.inv_onoff ? 'Sending Method' : 'Notifications?'}}</h5>
                                <label for="invitation_notification" class="switch_option capsule_btn">
                                    <input type="checkbox" id="invitation_notification" v-model="form.inv_onoff" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div v-show="form.inv_onoff">
                                <div class="section_wpr mb-2">
                                    <div class="edit_section">
                                        <sending-method v-model="form.invitation_notification" />
                                    </div>
                                </div>
                                <div v-if="form.invitation_notification === 1 || form.invitation_notification === 3">
                                    <div class="setting_wpr mb-4">
                                        <email-component v-model="invitationEmailContent" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultInvitationEmail" preview-module="playbook" ref="email-component" />
                                    </div>
                                </div>
                                <div v-if="form.invitation_notification === 2 || form.invitation_notification === 3">
                                    <div class="setting_wpr">
                                        <sms-component v-model="form.invitation_message.sms_message" :default-sms-handler="true" :handle-default-sms="handleDefaultInvitationSms" preview-module="playbook" :mediaFieldName="form.invitation_message.media" ref="sms-component" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="messageTab === 'resend'" class="mt-4">
                            <div class="section_wpr mb-2">
                                <div class="edit_section">
                                    <sending-method v-model="form.reset_notification" />
                                </div>
                            </div>
                            <div v-if="form.reset_notification === 1 || form.reset_notification === 3">
                                <div class="setting_wpr mb-4">
                                    <email-component v-model="passwordEmailContent" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultPasswordEmail" preview-module="playbook" ref="email-component" />
                                </div>
                            </div>
                            <div v-if="form.reset_notification === 2 || form.reset_notification === 3">
                                <div class="setting_wpr">
                                    <sms-component v-model="form.password_message.sms_message" :default-sms-handler="true" :handle-default-sms="handleDefaultPasswordSms" preview-module="playbook" :mediaFieldName="form.password_message.media" ref="sms-component" />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))
    const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))

    export default {
        name: 'Playbook Edit Delivery Message',

        data () {
            return {
                messageTab: 'invitation',
                invitationEmailContent: {
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                passwordEmailContent: {
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                form: {
                    id: '',
                    title: '',
                    inv_onoff: 0,
                    invitation_notification: 1,
                    invitation_show_email_logo: '',
                    invitation_email_logo: '',
                    invitation_show_email_signature: '',
                    invitation_email_signature: '',
                    reset_notification: 1,
                    reset_show_email_logo: '',
                    reset_email_logo: '',
                    reset_show_email_signature: '',
                    reset_email_signature: '',
                    invitation_message: {
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: '',
                    },
                    password_message: {
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: '',
                    },
                },
            }
        },

        props: {
            modelValue: Boolean,
            playbook: Object,
        },

        emit: ['update:modelValue'],

        components: {
            Form,
            SmsComponent,
            EmailComponent,
            SendingMethod,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            loader: state => state.playbookModule.playbookUpdateLoader,
        }),

        methods: {
            ...mapActions({
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form.id                      = vm.playbook.id;
                vm.form.title                   = vm.playbook.title;
                vm.form.inv_onoff               = vm.playbook.inv_onoff ? vm.playbook.inv_onoff : 0;
                vm.form.invitation_notification = vm.playbook.invitation_notification ? vm.playbook.invitation_notification : 1;
                vm.form.reset_notification      = vm.playbook.reset_notification ? vm.playbook.reset_notification : 1;
                vm.form.invitation_message      = JSON.parse(JSON.stringify(vm.playbook.invitation_message));
                vm.form.password_message        = JSON.parse(JSON.stringify(vm.playbook.password_message));

                vm.invitationEmailContent = {
                    defaultHandler: true,
                    subject: vm.playbook.invitation_message.email_subject ? vm.playbook.invitation_message.email_subject : '',
                    email: vm.playbook.invitation_message.email_message ? vm.playbook.invitation_message.email_message : '',
                    email_button_bg_color: vm.playbook.invitation_message.email_button_bg_color ? vm.playbook.invitation_message.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.playbook.invitation_message.email_button_text_color ? vm.playbook.invitation_message.email_button_text_color : '#FFF',
                    show_email_logo: vm.playbook.invitation_show_email_logo ? vm.playbook.invitation_show_email_logo : 0,
                    email_logo: vm.playbook.invitation_email_logo ? vm.playbook.invitation_email_logo : 0,
                    is_signature: vm.playbook.invitation_show_email_signature ? vm.playbook.invitation_show_email_signature : 0,
                    signature_id: vm.playbook.invitation_email_signature ? vm.playbook.invitation_email_signature : 0,
                    module_id: vm.playbook.id,
                };

                vm.passwordEmailContent = {
                    defaultHandler: true,
                    subject: vm.playbook.password_message.email_subject ? vm.playbook.password_message.email_subject : '',
                    email: vm.playbook.password_message.email_message ? vm.playbook.password_message.email_message : '',
                    email_button_bg_color: vm.playbook.password_message.email_button_bg_color ? vm.playbook.password_message.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.playbook.password_message.email_button_text_color ? vm.playbook.password_message.email_button_text_color : '#FFF',
                    show_email_logo: vm.playbook.reset_show_email_logo ? vm.playbook.reset_show_email_logo : 0,
                    email_logo: vm.playbook.reset_email_logo ? vm.playbook.reset_email_logo : 0,
                    is_signature: vm.playbook.reset_show_email_signature ? vm.playbook.reset_show_email_signature : 0,
                    signature_id: vm.playbook.reset_email_signature ? vm.playbook.reset_email_signature : 0,
                    module_id: vm.playbook.id,
                };
            },

            handleDefaultInvitationEmail () {
                const vm = this;
                let email = '<p>A new resource has just been added to your {{company_name}} member area.</p>'
                    email += '<p></p>';
                    email += '<p>Resource: {{playbook_name}} playbook.</p>';
                    email += '<p></p>';
                    email += '<p>{{auto_login_link}}</p>';
                    email += '<p></p>';
                    email += '<p>Cheers!</p>';
                    email += '<p>Team {{company_name}}</p>';

                vm.invitationEmailContent.subject = '[ACCESS] {{playbook_name}}!';
                vm.invitationEmailContent.email   = email;
            },

            handleDefaultInvitationSms () {
                const vm = this;

                vm.form.invitation_message.sms_message  = 'Hi {{first_name}}, \r\n You have been assigned the {{palybook_name}} playbook from {{company_name}}. Click here to access it. \r\n {{auto_login_sms_link}}';
            },

            handleDefaultPasswordEmail () {
                const vm = this;
                let email = '<p>Hi {{first_name}}!</p>';
                    email += '<p>Please access your client dashboard by clicking this button: </p>';
                    email += '<p></p>';
                    email += '<p>{{auto_login_link}}</p>';
                    email += '<p></p>';
                    email += '<p>Here are your login details for your dashboard. Save this message for future use.</p>';
                    email += '<p>Username:  {{user_name}} </p>';
                    email += '<p>Password: {{password}} </p>';
                    email += '<p>Thanks for being awesome {{first_name}}!</p>';
                    email += '<p>{{ company_owner }}</p>';
                    email += '<p>{{company_name}}</p>';
                    email += '<p></p>';
                    email += '<p><strong>Agreement to copyright:</strong> By accessing this information you agree to all copyrights by {{company_name}} to this intellectual property and agree not to duplicate, redistribute, or alter this information in any manner without written permission.</p>';


                vm.passwordEmailContent.subject = '[ACCESS] Your client dashboard';
                vm.passwordEmailContent.email   = email;
            },

            handleDefaultPasswordSms () {
                const vm = this;

                vm.form.password_message.sms_message  = 'Hi {{first_name}} here is your unique member ID for {{company_name}}: {{member_id}}.\r\nHere\'s your account quick access link for {{playbook_name}} playbook.\r\nAuto-login: {{auto_login_sms_link}}';
            },

            handleSubmitForm () {
                const vm  = this;

                const diliveryMessageform = vm.$refs['edit-delivery-message-form'];

                diliveryMessageform.validate().then((result) => {
                    if (result.valid) {
                        vm.handleUpdatePlaybookSetting(diliveryMessageform);
                    }
                })
            },

            handleUpdatePlaybookSetting (form) {
                const vm = this;

                vm.form.playbook_id                                 = vm.playbook.id;
                vm.form.invitation_message.email_subject            = vm.invitationEmailContent.subject;
                vm.form.invitation_message.email_message            = vm.invitationEmailContent.email;
                vm.form.invitation_show_email_logo                  = vm.invitationEmailContent.show_email_logo;
                vm.form.invitation_email_logo                       = vm.invitationEmailContent.email_logo;
                vm.form.invitation_show_email_signature             = vm.invitationEmailContent.is_signature;
                vm.form.invitation_email_signature                  = vm.invitationEmailContent.signature_id;
                vm.form.password_message.email_subject              = vm.passwordEmailContent.subject;
                vm.form.password_message.email_message              = vm.passwordEmailContent.email;
                vm.form.reset_show_email_logo                       = vm.passwordEmailContent.show_email_logo;
                vm.form.reset_email_logo                            = vm.passwordEmailContent.email_logo;
                vm.form.reset_show_email_signature                  = vm.passwordEmailContent.is_signature;
                vm.form.reset_email_signature                       = vm.passwordEmailContent.signature_id;
                vm.form.setFieldError                               = form.setFieldError;
                vm.form.invitation_message.email_button_bg_color    = vm.invitationEmailContent.email_button_bg_color;
                vm.form.invitation_message.email_button_text_color  = vm.invitationEmailContent.email_button_text_color;
                vm.form.password_message.email_button_bg_color      = vm.passwordEmailContent.email_button_bg_color;
                vm.form.password_message.email_button_text_color    = vm.passwordEmailContent.email_button_text_color;

                vm.updatePlaybookSetting(vm.form).then((result) => {
                    if (vm.$parent.$parent && vm.$parent.$parent.refreshPlaybook) {
                        vm.$parent.$parent.refreshPlaybook();
                    } else {
                        vm.$parent.refreshPlaybook();
                    }

                    vm.closeModal();
                })
            }
        }
    }
</script>